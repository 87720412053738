import ApiService from "@/core/services/ApiService";
interface ILogUser {
    username: string;
    ip: string;
    user_agent: string;
    access_token: boolean;
    date: string;
    _id: string;
}

const LogUser: Array<ILogUser> = [];
export { ILogUser };

export default LogUser;

export const getLogUsers = async (payload) => {
    const response = await ApiService.post("/log/user-access", { data: payload });
    return response.data;
};