
import { defineComponent, ref, onMounted } from "vue";
import moment from "moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import LogUser, { getLogUsers, ILogUser } from "./log";
export default defineComponent({
  name: "LogUserPage",
  components: {
    Datatable,
  },

  setup() {
    const tableData = ref<Array<ILogUser>>(LogUser);
    const tableKey = ref(0);
    const total_data = ref(0);
    const is_loading = ref(true);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const search = ref<string>("");
    const tableHeader = ref([
      {
        name: "Username",
        key: "username",
        sortable: true,
      },
      {
        name: "IP",
        key: "ip",
        sortable: true,
      },
      {
        name: "User Agent",
        key: "user_agent",
        sortable: true,
      },
      {
        name: "Tanggal",
        key: "date",
        sortable: true,
      },
    ]);
    const dataUser = ref<ILogUser>();

    const fetchData = async () => {
      const payload = {
        pages: currentPage.value,
        data_limit: itemsPerPage.value,
      };
      is_loading.value = true;
      const res = await getLogUsers(payload);
      tableData.value = res["data"];
      total_data.value = res["total"];
      is_loading.value = false;
      tableKey.value += 1;
    };

    const formatDate = (date) => {
      let tanggal = moment(date).format("YYYY-MM-DD HH:mm:ss");
      return tanggal;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("User", ["Log"]);
      fetchData();
      MenuComponent.reinitialization();
    });

    const currentChange = (current: number) => {
      currentPage.value = current;
      fetchData();
    };

    const itemsPerPageChange = (currentItemsPerPage: number) => {
      itemsPerPage.value = currentItemsPerPage;
      fetchData();
    };

    return {
      tableData,
      total_data,
      dataUser,
      tableHeader,
      is_loading,
      currentPage,
      search,
      itemsPerPage,
      currentChange,
      itemsPerPageChange,
      tableKey,
      formatDate,
    };
  },
});
